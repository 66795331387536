.menu-icon {
  width: 30px;
  height: 30px;
}
.icon1 {
  width: 20px;
  height: 20px;
  align-items: center;
}

.fullscreen-text {
  color: #2f80ed;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.maxView {
  background-color: #2f80ed;
  border-radius: 6px;
  margin-right: 10px;
}
.username {
  flex-grow: 1;
  font-family: Inter !important;
}

.sidebar-title {
  font-size: 32px;
  color: #000;
  font-weight: 600;
  /* margin-right: auto; */
  font-family: Inter;
}

.sidebar-subtitle {
  font-size: 32px;
  color: #2f80ed;
  font-weight: 600;
  font-family: Inter;
}

.sidebar-text {
  font-family: Inter !important;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  /* background-color: #2f80ed; */
  border-top: 1px solid #ccc;
}

.sidebar-icon {
  width: 20px;
  height: 20px;
}

.selected-section {
  font-weight: 700 !important;
  color: #2f80ed;
}

.section-title {
  color: #4a545e;
}

.normal-options {
  font-weight: 450 !important;
}

.selected-option {
  background-color: #e5eeff !important;
  color: #1e50c0 !important;
}

.normal-option {
  background-color: #fff;
  color: #4a545e !important;
}

.header-main {
  margin-bottom: 10px;
}

.logo {
  width: 200px;
  /* height: 50px; */
}
