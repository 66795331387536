.billing-header {
  font-size: 36px !important;
  color: var(--text);
  font-family: Inter !important;
}

.billing-heading-caption {
  color: var(--secondary);
  font-weight: 450;
  font-size: 18px;
}

.billing-date {
  font-family: Inter !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  color: var(--text);
}

.billing-next-date {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 450;
  color: var(--secondary);
}

.payment-methods {
  font-family: Inter !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #333333;
}

.add-new-btn {
  border-radius: 5px !important;
}

.dot {
  background-color: #1d7c4d;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.method-status {
  background-color: #c6f1da !important;
  border-radius: 5px !important;
}

.plan-card {
  position: relative;
}

.check-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 7px;
  right: 7px;
}

.wallet-circle {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #cfd6dd;
  align-items: center;
  justify-content: center;
  display: flex;
}

.plan-desc {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
