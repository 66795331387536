.section-heading {
  color: #7e8c9a;
}

.section-title {
  color: #4a545e;
  font-weight: 450 !important;
  font-size: 14px !important;
  font-family: Inter !important;
}

.menu-div {
  height: 10px;
}

.MuiCardHeader-content {
  width: 150px;
  text-overflow: ellipsis;
  text-wrap: wrap;
}
