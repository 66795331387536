.content {
  border: 1px solid #cfd6dd;
  border-radius: 5px;
  background-color: #fff;
}

.search-icon {
  height: 22px;
  width: 22px;
}

.search-input {
  /* max-width: 350px; */
  height: 40px;
  outline: 0 !important ;
  border: 0 !important;
}

.dropdown-icon {
  height: 20px;
  width: 20px;
}

.filterText {
  font-size: 14px;
  font-weight: 450;
  font-family: Inter;
}
