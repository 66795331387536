:root {
  --primary: #3062d4;
  --white: #ffffff;
  --text: #272e35;
  --font: Inter;
  --green: #27ae60;
  --time: #828282;
  --secondary: #555f6d;
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font) !important;
}

.text-d {
  font-family: var(--font) !important;
  color: var(--text) !important;
}

.bold-600 {
  font-weight: 600 !important;
}

.bold-700 {
  font-weight: 700 !important;
}

.bold-400 {
  font-weight: 400 !important;
}

.time-color {
  color: var(--time) !important;
}

.primary-color {
  color: var(--primary) !important;
}

.primary-bg {
  background-color: var(--primary) !important;
}

.secondary-bg {
  background-color: var(--secondary) !important;
}

.nopulse {
  display: flex;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #6c63ff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(120, 55, 218, 0.4);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.transcript-icon {
  color: var(--green);
  width: 22px;
  height: 22px;
}

.icon-20 {
  width: 20px !important;
  height: 20px !important;
}

.translation-icon {
  color: var(--primary);
}

.tts-icon {
  height: 40px;
  min-width: 40px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

@media screen and (max-width: 768px) {
  .font-24 {
    font-size: 18px !important;
  }
}

ul li {
  font-size: 14px;
  /* margin-left: 10px; */
  list-style-type: circle;
}

.acc-field-head {
  font-weight: 600 !important;
  color: #333;
}
