.greeting-title {
  font-size: 32px !important;
}

.language-select-text {
  font-size: 24px !important;
}

.connected-text {
  font-size: 28px !important;
}

.custom-btn {
  width: 300px;
  height: 50px;
}

.end-btn {
  /* width: 180px; */
  /* min-width: 180px; */
  height: 50px;
  min-width: 50px !important;
  border-radius: 7px !important;
}

.generate-link {
  background-color: #1c7d4d !important;
  color: #fff !important;
}

.prev-enc {
  background-color: #272f34 !important;
  color: #fff !important;
}

.mute-text {
  color: #272f34 !important;
}

.mute-unmute {
  background-color: #f59738 !important;
  /* color: #fff !important; */
}

@media screen and (max-width: 768px) {
  .greeting-title {
    font-size: 22px !important;
  }
  .language-select-text {
    font-size: 18px !important;
  }
  .connected-text {
    font-size: 22px !important;
  }
  .end-btn {
    width: 90%;
  }
}
