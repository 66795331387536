.nameText {
  color: #272e35;
  font-weight: 700 !important;
  font-family: Inter !important;
  font-size: 22px !important;
}
.start-call {
  background-color: #1d7c4d !important;
  border: 0;
}
.join-call {
  background-color: #ffa500 !important;
  border: 0;
}
.copy-link {
  background-color: #ffa500 !important;
  border: 0;
}
.completed-link {
  background-color: var(--secondary) !important;
  border: 0;
}

.completed-card {
  /* opacity: 0.5; */
  background-color: #f4e391 !important;
}

@media screen and (max-width: 768px) {
  .nameText {
    font-size: 20px !important;
  }
}
