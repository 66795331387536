.contact-support {
  font-size: 14px;
  color: #2f80ed;
}

.login-right-half {
  background-image: url(../../assets/CareSpeak_Logo.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.logo-view {
  margin-top: 55%;
  margin-left: 15%;
}

.logoNormal {
  font-size: 48px;
  color: #333333;
  font-family: Inter;
  font-weight: 400;
  align-self: center;
}

.logoColored {
  color: #2f80ed;
}

.logo-subtext {
  color: #333333;
  font-size: 36px;
  font-family: Inter;
  font-weight: 400;
}

.landing-title {
  font-family: "Inter";
  /* text-align: center; */
  letter-spacing: 0.1px;
  line-height: 64px;
  font-weight: 700;
}

.login-text {
  font-size: 24px;
}

.login-buttons {
  border: 1px solid #ccc !important;
  background-color: #fff !important;
  font-size: 18px !important;
  color: #4a545e !important;
  font-weight: 450 !important;
  width: 350px;
}

.login-buttons:hover {
  background-color: #2f80ed !important;
  color: #fff !important;
}
/* 
.login-view {
  margin: 15% 0px;
} */

.sign-in-text {
  font-size: 30px;
}

.logo-login {
  width: 350px;
  /* height: 75px; */
}

.subhead2 {
  /* color: #828282; */
  font-size: 20px;
  font-weight: 600;
}

.labels {
  /* color: #828282; */
  font-size: 16px;
  font-weight: 600;
}
