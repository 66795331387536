.convo-text {
  font-size: 18px !important;
  color: #333333;
}

.conversation-list {
  overflow-y: auto;
  height: 400px;
}

.responsive-textarea-width {
  width: 650px;
}

@media screen and (max-width: 768px) {
  .responsive-textarea-width {
    width: 250px;
  }
}

@media screen and (max-width: 1024px) {
  .responsive-textarea-width {
    width: 400px;
  }
}
