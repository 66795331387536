.sessions-header {
  font-size: 18px !important;
  font-family: Inter !important;
  font-weight: 600 !important;
}

.sessions-heading-caption {
  color: var(--secondary);
  font-weight: 450 !important;
  font-size: 14px !important;
  line-height: 24px;
}

.filter-name {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 450;
  line-height: 24px !important;
}

.filter-icon {
  width: 16px;
}
